import React, { useState } from "react";
import SlideToggle from "react-slide-toggle";
import { Link } from "gatsby";
// import { useLocation, useNavigate } from "@reach/router";

function MobileMenu() {
  //   const router = useNavigate();
  const [searchTerm, setSearchTerm] = useState("");

  //   useEffect(() => {
  //     router.events.on("routeChangeComplete", hideMobileMenu);
  //   }, []);

  function hideMobileMenu() {
    document.querySelector("body").classList.remove("mmenu-active");
  }

  function onSearchChange(e) {
    setSearchTerm(e.target.value);
  }

  function onSubmitSearchForm(e) {
    e.preventDefault();
    // router.push({
    //   pathname: "/shop",
    //   query: {
    //     searchTerm: searchTerm,
    //     category: "",
    //   },
    // });
  }

  return (
    <div className="mobile-menu-container">
      <div className="mobile-menu-wrapper">
        <span className="mobile-menu-close" onClick={hideMobileMenu}>
          <i className="icon-close"></i>
        </span>

        <form
          action="#"
          method="get"
          onSubmit={onSubmitSearchForm}
          className="mobile-search"
        >
          <label htmlFor="mobile-search" className="sr-only">
            Search
          </label>
          <input
            type="text"
            className="form-control"
            value={searchTerm}
            onChange={onSearchChange}
            name="mobile-search"
            id="mobile-search"
            placeholder="Search product ..."
            required
          />
          <button className="btn btn-primary" type="submit">
            <i className="icon-search"></i>
          </button>
        </form>

        <nav className="mobile-nav">
          <ul className="mobile-menu">
            <SlideToggle collapsed={true}>
              {({ onToggle, setCollapsibleElement, toggleState }) => (
                <li
                  className={
                    toggleState.toLowerCase() === "expanded" ? "open" : ""
                  }
                >
                  <Link to="/">
                    Home
                    <span
                      // className="mmenu-btn"
                      onClick={(e) => {
                        onToggle(e);
                        e.preventDefault();
                      }}
                    ></span>
                  </Link>

                  {/* <ul ref={setCollapsibleElement}>
                    <li>
                      <a
                        to="https://d-themes.com/react/molla/demo-1/"
                        target="_blank"
                      >
                        01 - furniture store
                      </a>
                    </li>
                    <li>
                      <a
                        to="https://d-themes.com/react/molla/demo-2/"
                        target="_blank"
                      >
                        02 - furniture store
                      </a>
                    </li>
                 
                  </ul> */}
                </li>
              )}
            </SlideToggle>
            <SlideToggle collapsed={true}>
              {({ onToggle, setCollapsibleElement, toggleState }) => (
                <li
                  className={
                    toggleState.toLowerCase() === "expanded" ? "open" : ""
                  }
                >
                  <Link to="/shop">
                    Shop
                    <span
                      // className="mmenu-btn"
                      onClick={(e) => {
                        onToggle(e);
                        e.preventDefault();
                      }}
                    ></span>
                  </Link>

                  {/* <ul ref={setCollapsibleElement}>
                    <li>
                      <Link to="/shop">Shop List</Link>
                    </li>
                    <li>
                      <Link to="/shop/sidebar/2cols">Shop Grid 2 Columns</Link>
                    </li>
                    <li>
                      <Link to="/shop/sidebar/3cols">Shop Grid 3 Columns</Link>
                    </li>
                    <li>
                      <Link to="/shop/sidebar/4cols">Shop Grid 4 Columns</Link>
                    </li>
                    <li>
                      <Link to="/shop/market">
                        <span>
                          Shop Market<span className="tip tip-new">New</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop/nosidebar/boxed">
                        <span>
                          Shop Boxed No Sidebar
                          <span className="tip tip-hot">Hot</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop/nosidebar/fullwidth">
                        Shop Fullwidth No Sidebar
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop/category/boxed">
                        Product Category Boxed
                      </Link>
                    </li>
                    <li>
                      <Link to="/shop/category/fullwidth">
                        <span>
                          Product Category Fullwidth
                          <span className="tip tip-new">New</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/cart">Cart</Link>
                    </li>
                    <li>
                      <Link to="/checkout">Checkout</Link>
                    </li>
                    <li>
                      <Link to="/wishlist">Wishlist</Link>
                    </li>
                    <li>
                      <Link to="/shop/dashboard">My Account</Link>
                    </li>
                    <li>
                      <Link to="#">Lookbook</Link>
                    </li>
                  </ul> */}
                </li>
              )}
            </SlideToggle>
            <SlideToggle collapsed={true}>
              {({ onToggle, setCollapsibleElement, toggleState }) => (
                <li
                  className={
                    toggleState.toLowerCase() === "expanded" ? "open" : ""
                  }
                >
                  <Link to="/about" className="sf-without-ul">
                    About
                    <span
                      // className="mmenu-btn"
                      onClick={(e) => {
                        onToggle(e);
                        e.preventDefault();
                      }}
                    ></span>
                  </Link>
                  {/* <ul ref={setCollapsibleElement}>
                    <li>
                      <Link to="/products/dark-yellow-lace-cut-out-swing-dress">
                        Default
                      </Link>
                    </li>
                    <li>
                      <Link to="/product/centered/beige-ring-handle-circle-cross-body-bag">
                        Centered
                      </Link>
                    </li>
                    <li>
                      <Link to="/product/extended/yellow-tie-strap-block-heel-sandals">
                        <span>
                          Extended Info<span className="tip tip-new">New</span>
                        </span>
                      </Link>
                    </li>
                    <li>
                      <Link to="/product/gallery/beige-metal-hoop-tote-bag">
                        Gallery
                      </Link>
                    </li>
                    <li>
                      <Link to="/product/sticky/brown-faux-fur-longline-coat">
                        Sticky Info
                      </Link>
                    </li>
                    <li>
                      <Link to="/product/sidebar/beige-v-neck-button-cardigan">
                        Boxed With Sidebar
                      </Link>
                    </li>
                    <li>
                      <Link to="/product/fullwidth/black-faux-leather-chain-trim-sandals">
                        Full Width
                      </Link>
                    </li>
                    <li>
                      <Link to="/product/masonry/black-denim-dungaree-dress">
                        Masonry Sticky Info
                      </Link>
                    </li>
                  </ul> */}
                </li>
              )}
            </SlideToggle>
            <SlideToggle collapsed={true}>
              {({ onToggle, setCollapsibleElement, toggleState }) => (
                <li
                  className={
                    toggleState.toLowerCase() === "expanded" ? "open" : ""
                  }
                >
                  <Link to="/contact">
                    Contact
                    <span
                      // className="mmenu-btn"
                      onClick={(e) => {
                        onToggle(e);
                        e.preventDefault();
                      }}
                    ></span>
                  </Link>
                  {/* <ul ref={setCollapsibleElement}>
                    <SlideToggle collapsed={true}>
                      {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <li
                          className={
                            toggleState.toLowerCase() === "expanded"
                              ? "open"
                              : ""
                          }
                        >
                          <Link to="/about" className="sf-without-ul">
                            About{" "}
                            <span
                              // className="mmenu-btn"
                              onClick={(e) => {
                                onToggle(e);
                                e.preventDefault();
                              }}
                            ></span>
                          </Link>

                          <ul ref={setCollapsibleElement}>
                            <li>
                              <Link to="/about">About 01</Link>
                            </li>
                            <li>
                              <Link to="/about-2">About 02</Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    </SlideToggle>
                    <SlideToggle collapsed={true}>
                      {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <li
                          className={
                            toggleState.toLowerCase() === "expanded"
                              ? "open"
                              : ""
                          }
                        >
                          <Link to="/contact" className="sf-without-ul">
                            Contact{" "}
                            <span
                              // className="mmenu-btn"
                              onClick={(e) => {
                                onToggle(e);
                                e.preventDefault();
                              }}
                            ></span>
                          </Link>

                          <ul ref={setCollapsibleElement}>
                            <li>
                              <Link to="/contact">Contact 01</Link>
                            </li>
                            <li>
                              <Link to="/contact-2">Contact 02</Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    </SlideToggle>
                    <li>
                      <Link to="/pages/login">Login</Link>
                    </li>
                    <li>
                      <Link to="/pages/faq">FAQs</Link>
                    </li>
                    <li>
                      <Link to="/pages/404">Error 404</Link>
                    </li>
                    <li>
                      <Link to="/pages/coming-soon">Coming Soon</Link>
                    </li>
                  </ul> */}
                </li>
              )}
            </SlideToggle>
            {/* <SlideToggle collapsed={true}>
              {({ onToggle, setCollapsibleElement, toggleState }) => (
                <li
                  className={
                    toggleState.toLowerCase() === "expanded" ? "open" : ""
                  }
                >
                  <Link to="/blog/classic">
                    Blog
                    <span
                      // className="mmenu-btn"
                      onClick={(e) => {
                        onToggle(e);
                        e.preventDefault();
                      }}
                    ></span>
                  </Link> */}

            {/* <ul ref={setCollapsibleElement}>
                    <li>
                      <Link to="/blog/classic">Classic</Link>
                    </li>
                    <li>
                      <Link to="/blog/listing">Listing</Link>
                    </li>
                    <SlideToggle collapsed={true}>
                      {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <li
                          className={
                            toggleState.toLowerCase() === "expanded"
                              ? "open"
                              : ""
                          }
                        >
                          <Link to="#" className="sf-without-ul">
                            Grid{" "}
                            <span
                              // className="mmenu-btn"
                              onClick={(e) => {
                                onToggle(e);
                                e.preventDefault();
                              }}
                            ></span>
                          </Link>
                          <ul ref={setCollapsibleElement}>
                            <li>
                              <Link to="/blog/grid/2cols">Grid 2 columns</Link>
                            </li>
                            <li>
                              <Link to="/blog/grid/3cols">Grid 3 columns</Link>
                            </li>
                            <li>
                              <Link to="/blog/grid/4cols">Grid 4 columns</Link>
                            </li>
                            <li>
                              <Link to="/blog/grid/sidebar">Grid sidebar</Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    </SlideToggle>
                    <SlideToggle collapsed={true}>
                      {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <li
                          className={
                            toggleState.toLowerCase() === "expanded"
                              ? "open"
                              : ""
                          }
                        >
                          <Link to="#" className="sf-without-ul">
                            Masonry{" "}
                            <span
                              // className="mmenu-btn"
                              onClick={(e) => {
                                onToggle(e);
                                e.preventDefault();
                              }}
                            ></span>
                          </Link>
                          <ul ref={setCollapsibleElement}>
                            <li>
                              <Link to="/blog/masonry/2cols">
                                Masonry 2 columns
                              </Link>
                            </li>
                            <li>
                              <Link to="/blog/masonry/3cols">
                                Masonry 3 columns
                              </Link>
                            </li>
                            <li>
                              <Link to="/blog/masonry/4cols">
                                Masonry 4 columns
                              </Link>
                            </li>
                            <li>
                              <Link to="/blog/masonry/sidebar">
                                Masonry sidebar
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    </SlideToggle>
                    <SlideToggle collapsed={true}>
                      {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <li
                          className={
                            toggleState.toLowerCase() === "expanded"
                              ? "open"
                              : ""
                          }
                        >
                          <Link to="#" className="sf-without-ul">
                            Mask{" "}
                            <span
                              // className="mmenu-btn"
                              onClick={(e) => {
                                onToggle(e);
                                e.preventDefault();
                              }}
                            ></span>
                          </Link>
                          <ul ref={setCollapsibleElement}>
                            <li>
                              <Link to="/blog/mask/grid">Blog Mask Grid</Link>
                            </li>
                            <li>
                              <Link to="/blog/mask/masonry">
                                Blog Mask Masonry
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    </SlideToggle>
                    <SlideToggle collapsed={true}>
                      {({ onToggle, setCollapsibleElement, toggleState }) => (
                        <li
                          className={
                            toggleState.toLowerCase() === "expanded"
                              ? "open"
                              : ""
                          }
                        >
                          <Link
                            to="/blog/single/default/cras-ornare-tristique-elit."
                            className="sf-without-ul"
                          >
                            Single Post{" "}
                            <span
                              // className="mmenu-btn"
                              onClick={(e) => {
                                onToggle(e);
                                e.preventDefault();
                              }}
                            ></span>
                          </Link>
                          <ul ref={setCollapsibleElement}>
                            <li>
                              <Link to="/blog/single/default/cras-ornare-tristique-elit.">
                                Default with sidebar
                              </Link>
                            </li>
                            <li>
                              <Link to="/blog/single/fullwidth/fusce-pellentesque-suscipit.">
                                Fullwidth no sidebar
                              </Link>
                            </li>
                            <li>
                              <Link to="/blog/single/sidebar/utaliquam-sollicitzdvudin-leo">
                                Fullwidth with sidebar
                              </Link>
                            </li>
                          </ul>
                        </li>
                      )}
                    </SlideToggle>
                  </ul> */}
            {/* </li>
              )}
            </SlideToggle>
            <SlideToggle collapsed={true}>
              {({ onToggle, setCollapsibleElement, toggleState }) => (
                <li
                  className={
                    toggleState.toLowerCase() === "expanded" ? "open" : ""
                  }
                >
                  <Link to="/elements" className="sf-without-ul">
                    Elements
                    <span
                      // className="mmenu-btn"
                      onClick={(e) => {
                        onToggle(e);
                        e.preventDefault();
                      }}
                    ></span>
                  </Link> */}

            {/* <ul ref={setCollapsibleElement}>
                    <li>
                      <Link to="/elements/products">Products</Link>
                    </li>
                    <li>
                      <Link to="/elements/typography">Typography</Link>
                    </li>
                    <li>
                      <Link to="/elements/titles">Titles</Link>
                    </li>
                    <li>
                      <Link to="/elements/banners">Banners</Link>
                    </li>
                    <li>
                      <Link to="/elements/categories">Product Category</Link>
                    </li>
                    <li>
                      <Link to="/elements/video-banners">Video Banners</Link>
                    </li>
                    <li>
                      <Link to="/elements/buttons">Buttons</Link>
                    </li>
                    <li>
                      <Link to="/elements/accordions">Accordions</Link>
                    </li>
                    <li>
                      <Link to="/elements/tabs">Tabs</Link>
                    </li>
                    <li>
                      <Link to="/elements/testimonials">Testimonials</Link>
                    </li>
                    <li>
                      <Link to="/elements/blog-posts">Blog Posts</Link>
                    </li>
                    <li>
                      <Link to="/elements/cta">Call to Action</Link>
                    </li>
                    <li>
                      <Link to="/elements/icon-boxes">Icon Boxes</Link>
                    </li>
                  </ul> */}
            {/* </li>
              )}
            </SlideToggle> */}
          </ul>
        </nav>

        <div className="social-icons">
          <Link to="#" className="social-icon" title="Facebook">
            <i className="icon-facebook-f"></i>
          </Link>
          <Link to="#" className="social-icon" title="Twitter">
            <i className="icon-twitter"></i>
          </Link>
          <Link to="#" className="social-icon" title="Instagram">
            <i className="icon-instagram"></i>
          </Link>
          <Link to="#" className="social-icon" title="Youtube">
            <i className="icon-youtube"></i>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default React.memo(MobileMenu);
