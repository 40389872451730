import React from "react";
import { Link } from "gatsby";
import HeaderSearch from "./header-search";
import CartMenu from "./cart-menu";
import MainMenu from "./main-menu";
import StickyHeader from "./sticky-header";

function Header() {
  function openMobileMenu() {
    document.querySelector("body").classList.add("mmenu-active");
  }

  return (
    <header className="header header-5 position-relative">
      <StickyHeader>
        <div className="header-middle sticky-header">
          <div className="container-fluid">
            <div className="header-left">
              <button className="mobile-menu-toggler" onClick={openMobileMenu}>
                <span className="sr-only">Toggle mobile menu</span>
                <i className="icon-bars"></i>
              </button>

              <Link to="/" className="logo">
                <img
                  src="/images/brands/logo.webp"
                  alt="Civsav"
                  width={145}
                  height={25}
                />
              </Link>
              <MainMenu />
            </div>

            <div className="header-right">
              <HeaderSearch />
              <Link to="/wishlist" className="wishlist-link" title="Wishlist">
                <i className="icon-heart-o"></i>
              </Link>
              <CartMenu />
            </div>
          </div>
        </div>
      </StickyHeader>
    </header>
  );
}

export default Header;
