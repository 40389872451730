// import { useRouter } from "next/router";
import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import { isBrowser } from "../../../utils";

function Footer() {
  //   const router = useRouter("");
  const [isBottomSticky, setIsBottomSticky] = useState(false);
  const [containerClass, setContainerClass] = useState("container");

  useEffect(() => {
    handleBottomSticky();
    setContainerClass();
    //   router.asPath.includes("fullwidth") ? "container-fluid" : "container"
  }, []);

  useEffect(() => {
    if (isBrowser) {
      window.addEventListener("resize", handleBottomSticky, { passive: true });
      return () => {
        window.removeEventListener("resize", handleBottomSticky);
      };
    }
  }, []);

  function handleBottomSticky() {
    // setIsBottomSticky(
    //   router.pathname.includes("product/default") && window.innerWidth > 991
    // );
  }

  return (
    <footer className="footer footer-2">
      <div className="footer-middle">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-lg-6">
              <div className="widget widget-about">
                <img
                  src="/images/brands/logofooter.png"
                  className="footer-logo"
                  alt="Footer Logo"
                  width="205"
                  height="25"
                />
                <p>
                  Praesent dapibus, neque id cursus ucibus, tortor neque egestas
                  augue, eu vulputate magna eros eu erat. Aliquam erat volutpat.
                  Nam dui mi, tincidunt quis, accumsan porttitor, facilisis
                  luctus, metus.{" "}
                </p>

                {/* <div className="widget-about-info">
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <span className="widget-about-title">
                        Got Question? Call us 24/7
                      </span>
                      <a href="tel:123456789">+0123 456 789</a>
                    </div>
                    <div className="col-sm-6 col-md-8">
                      <span className="widget-about-title">Payment Method</span>
                      <figure className="footer-payments">
                        <img
                          src="https://d-themes.com/react/molla/demo-5/images/payments.png"
                          alt="Payment methods"
                          width="272"
                          height="20"
                        />
                      </figure>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <div className="col-sm-4 col-lg-2">
              <div className="widget">
                <h4 className="widget-title">Information</h4>

                <ul className="widget-list">
                  <li>
                    <Link to="/about">About Civsav</Link>
                  </li>
                  <li>
                    <Link to="/about">How to shop on Civsav</Link>
                  </li>
                  <li>
                    <Link to="/about">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/contact">Contact us</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-4 col-lg-2">
              <div className="widget">
                <h4 className="widget-title">Customer Service</h4>

                <ul className="widget-list">
                  <li>
                    <Link to="#">Payment Methods</Link>
                  </li>
                  <li>
                    <Link to="#">Money-back guarantee!</Link>
                  </li>
                  <li>
                    <Link to="#">Returns</Link>
                  </li>
                  <li>
                    <Link to="#">Shipping</Link>
                  </li>
                  <li>
                    <Link to="#">Terms and conditions</Link>
                  </li>
                  <li>
                    <Link to="#">Privacy Policy</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-sm-4 col-lg-2">
              <div className="widget">
                <h4 className="widget-title">My Account</h4>

                <ul className="widget-list">
                  <li>
                    <Link to="#">Sign In</Link>
                  </li>
                  <li>
                    <Link to="/cart">View Cart</Link>
                  </li>
                  <li>
                    <Link to="/wishlist">My Wishlist</Link>
                  </li>
                  <li>
                    <Link to="#">Track My Order</Link>
                  </li>
                  <li>
                    <Link to="#">Help</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="footer-bottom">
        <div className="container">
          <p className="footer-copyright mb-0">
            Copyright © {new Date().getFullYear()} Civsav. All Rights Reserved.
          </p>
          <ul className="footer-menu">
            <li>
              <Link to="#">Terms Of Use</Link>
            </li>
            <li>
              <Link to="#">Privacy Policy</Link>
            </li>
          </ul>

          <div className="social-icons social-icons-color">
            <span className="social-label">Social Media</span>

            <Link
              to="#"
              className="social-icon social-facebook"
              rel="noopener noreferrer"
              title="Facebook"
            >
              <i className="icon-facebook-f"></i>
            </Link>
            <Link
              to="#"
              className="social-icon social-twitter"
              rel="noopener noreferrer"
              title="Twitter"
            >
              <i className="icon-twitter"></i>
            </Link>
            <Link
              to="#"
              className="social-icon social-instagram"
              rel="noopener noreferrer"
              title="Instagram"
            >
              <i className="icon-instagram"></i>
            </Link>
            <Link
              to="#"
              className="social-icon social-youtube"
              rel="noopener noreferrer"
              title="Youtube"
            >
              <i className="icon-youtube"></i>
            </Link>
            <Link
              to="#"
              className="social-icon social-pinterest"
              rel="noopener noreferrer"
              title="Pinterest"
            >
              <i className="icon-pinterest"></i>
            </Link>
          </div>
        </div>
      </div>
      {isBottomSticky ? <div className="mb-10"></div> : ""}
    </footer>
  );
}

export default React.memo(Footer);
