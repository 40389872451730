import React, { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useLocation } from "@reach/router";

import "react-image-lightbox/style.css";
import "react-toastify/dist/ReactToastify.min.css";

import Header from "../common/header";
import Footer from "../common/footer";
import VideoModal from "../modals/video-modal";
import QuickViewModal from "../modals/quickview-modal";
import QuickViewModalTwo from "../modals/quickview-modal-two";
import MobileMenu from "../common/header/mobile-menu";

import { isSafariBrowser, isEdgeBrowser, isBrowser } from "../../utils";

function Layout({ children, hideQuick, hideVideo }) {
  const router = useLocation();
  let scrollTop;

  useEffect(() => {
    if (isBrowser && router.pathname.includes("pages/coming-soon")) {
      document.querySelector("header").classList.add("d-none");
      //   document.querySelector("footer").classList.add("d-none");
    } else {
      document.querySelector("header").classList.remove("d-none");
      //   document.querySelector("footer").classList.remove("d-none");
    }
  }, [router.pathname]);

  useEffect(() => {
    if (isBrowser) {
      // hideQuick();
      // hideVideo();
      scrollTop = document.querySelector("#scroll-top");
      window.addEventListener("scroll", scrollHandler, false);
    }
  }, []);

  function toScrollTop() {
    if (isSafariBrowser() || isEdgeBrowser()) {
      let pos = window.pageYOffset;
      let timerId = setInterval(() => {
        if (pos <= 0) clearInterval(timerId);
        window.scrollBy(0, -120);
        pos -= 120;
      }, 1);
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }

  function scrollHandler() {
    if (isBrowser) {
      if (window.pageYOffset >= 400) {
        scrollTop.classList.add("show");
      } else {
        scrollTop.classList.remove("show");
      }
    }
  }

  function hideMobileMenu() {
    document.querySelector("body").classList.remove("mmenu-active");
  }

  return (
    <>
      <div className="page-wrapper">
        <Header />
        {children}
        <Footer />
      </div>
      <div className="mobile-menu-overlay" onClick={hideMobileMenu}></div>
      <button id="scroll-top" title="Back to top" onClick={toScrollTop}>
        <i className="icon-arrow-up"></i>
      </button>
      <MobileMenu />

      <ToastContainer
        autoClose={3000}
        duration={300}
        newestOnTo={true}
        className="toast-container"
        position="top-right"
        closeButton={false}
        hideProgressBar={true}
        newestOnTop={true}
        draggable={false}
      />
      {/* {isBrowser && router.pathname === "/" ? (
        <QuickViewModalTwo />
      ) : (
        <QuickViewModal />
      )} */}

      <VideoModal />
    </>
  );
}

export default Layout;
