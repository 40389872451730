import React from "react";
import { Script } from "gatsby";
import "./src/styles/global.css";
import "./src/assets/css/bootstrap.min.css";
import "./src/assets/scss/style.scss";
import "./src/assets/scss/plugins/owl-carousel/owl.carousel.scss";
import "./src/assets/css/fonts-molla.min.css";
import "./src/assets/css/fonts-flaming.css";
import "./src/assets/vendor/line-awesome/css/line-awesome.min.css";
import Layout from "./src/components/layout";

export const wrapPageElement = ({ element }) => {
  return (
    <Layout>
      <Script src="js/jquery.min.js" async></Script>
      {element}
    </Layout>
  );
};
