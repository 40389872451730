import React from "react";
import { useLocation } from "@reach/router";
import { Link } from "gatsby";

function MainMenu() {
  const router = useLocation();
  let path = router.asPath;
  let query = router.query;

  function showAllDemos(e) {
    let demoItems = document.querySelectorAll(".demo-item.hidden");

    for (let i = 0; i < demoItems.length; i++) {
      demoItems[i].classList.toggle("show");
    }

    document
      .querySelector(".view-all-demos")
      .classList.toggle("disabled-hidden");
    e.preventDefault();
  }

  return (
    <nav className="main-nav">
      <ul className="menu sf-arrows">
        <li
          className={`megamenu-container ${
            router.pathname === "/" ? "active" : ""
          }`}
          id="menu-home"
        >
          <Link to="/" className="sf-without-ul">
            Home
          </Link>
        </li>
        <li className={router.pathname.indexOf("/shop") > -1 ? "active" : ""}>
          <Link to="/shop" className="sf-with-ul">
            Shop
          </Link>

          <div className="megamenu megamenu-md">
            <div className="row no-gutters">
              <div className="col-md-6">
                <div className="menu-col">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="menu-title">Product Category</div>
                      <ul>
                        <li
                          className={
                            router.pathname.indexOf("shop/category/boxed") > -1
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="/shop/category">Product Categories</Link>
                        </li>
                        <li
                          className={
                            router.pathname.indexOf("shop/category/fullwidth") >
                            -1
                              ? "active"
                              : ""
                          }
                        >
                          <Link to="/shop/category">
                            <span>
                              Product Category
                              <span className="tip tip-new">New</span>
                            </span>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="banner banner-overlay">
                  <Link to="/shop" className="banner banner-menu">
                    <img
                      src="https://d-themes.com/react/molla/demo-5/images/menu/banner-1.jpg"
                      alt="Banner"
                    />

                    <div className="banner-content banner-content-top">
                      <div className="banner-title text-white">
                        Last <br />
                        Chance
                        <br />
                        <span>
                          <strong>Sale</strong>
                        </span>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </li>
        <li className={router.pathname.indexOf("pages") > -1 ? "active" : ""}>
          <Link to="#" className="sf-with-ul">
            Pages
          </Link>

          <ul>
            <li
              className={router.pathname.indexOf("/about") > -1 ? "active" : ""}
            >
              <Link to="/about" className="sf-without-ul">
                About
              </Link>
            </li>
            <li
              className={
                router.pathname.indexOf("/contact") > -1 ? "active" : ""
              }
            >
              <Link to="/contact" className="sf-without-ul">
                Contact
              </Link>
            </li>
            <li
              className={router.pathname.indexOf("/login") > -1 ? "active" : ""}
            >
              <Link to="/login">Login</Link>
            </li>
            <li
              className={router.pathname.indexOf("/faq") > -1 ? "active" : ""}
            >
              <Link to="/faq">FAQs</Link>
            </li>
          </ul>
        </li>
        <li className={router.pathname.indexOf("/about") > -1 ? "active" : ""}>
          <Link to="/blog" className="sf-without-ul">
            Blog
          </Link>
        </li>
        <li
          className={router.pathname.indexOf("/contact") > -1 ? "active" : ""}
        >
          <Link to="/contact" className="sf-without-ul">
            Contact
          </Link>
        </li>
      </ul>
    </nav>
  );
}

export default MainMenu;
