exports.components = {
  "component---src-pages-about-jsx": () => import("./../../../src/pages/about.jsx" /* webpackChunkName: "component---src-pages-about-jsx" */),
  "component---src-pages-blog-1-jsx": () => import("./../../../src/pages/blog/1.jsx" /* webpackChunkName: "component---src-pages-blog-1-jsx" */),
  "component---src-pages-blog-jsx": () => import("./../../../src/pages/blog.jsx" /* webpackChunkName: "component---src-pages-blog-jsx" */),
  "component---src-pages-cart-jsx": () => import("./../../../src/pages/cart.jsx" /* webpackChunkName: "component---src-pages-cart-jsx" */),
  "component---src-pages-checkout-jsx": () => import("./../../../src/pages/checkout.jsx" /* webpackChunkName: "component---src-pages-checkout-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-myaccount-jsx": () => import("./../../../src/pages/myaccount.jsx" /* webpackChunkName: "component---src-pages-myaccount-jsx" */),
  "component---src-pages-products-product-1-jsx": () => import("./../../../src/pages/products/product-1.jsx" /* webpackChunkName: "component---src-pages-products-product-1-jsx" */),
  "component---src-pages-shop-category-jsx": () => import("./../../../src/pages/shop/category.jsx" /* webpackChunkName: "component---src-pages-shop-category-jsx" */),
  "component---src-pages-shop-index-jsx": () => import("./../../../src/pages/shop/index.jsx" /* webpackChunkName: "component---src-pages-shop-index-jsx" */),
  "component---src-pages-wishlist-jsx": () => import("./../../../src/pages/wishlist.jsx" /* webpackChunkName: "component---src-pages-wishlist-jsx" */),
  "component---src-template-product-template-jsx": () => import("./../../../src/Template/ProductTemplate.jsx" /* webpackChunkName: "component---src-template-product-template-jsx" */)
}

